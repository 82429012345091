.App {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .short-desc {
    text-align: center;
  }
  .title-text {
    font-size: 48px;
  }