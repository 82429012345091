body {
  background-color: #2d3a53;
  color: white;
  height: 100%;
}
a {
  color: white;
}

input,
textarea {
  font-family: 'Helvetica Neue', sans-serif;
}
